import { defineStore } from 'pinia'
import axios from 'axios'

export const usePlayerStore = defineStore('playerStore', {
  state: () => ({
    newGame: true,
    gamestep: 0,
    setup: null,
    players: [],
    firstPlayer: null,
    word_fellow: '',
    word_undercover: '',
  }),
  getters: {
  },
  actions: {
    setupPlayer (playerId, playerPseudo) {
      this.players[playerId].pseudo = playerPseudo
      if (this.checkAllPlayersReady()) {
        this.getFirstPlayer()
        this.gamestep = 2
      }
    },
    getFirstPlayer () {
      var glop = true 
      while (glop) {
        var n = Math.floor(Math.random() * this.players.length)
        if (this.players[n].role !== 'mrwhite') {
          this.firstPlayer = this.players[n]
          break 
        }
      }
    },
    getWords () {
      axios.get("https://api.undercover.nyanpasu.moe/words/")
      .then( (response) => {
        this.word_fellow = response.data.fellow
        this.word_undercover = response.data.undercover
      })
      .catch( (error) => {
        console.log("Pas Glop")
        console.log(error)
      })
      return null
    },
    checkAllPlayersReady () {
      for (var p of this.players) {
        if (p.pseudo === '')
          return false 
      }
      return true 
    },
    createNewGame (setup) {
      this.setup = setup 
      this.players = []

      var i = 0
      for (i = 0 ; i < this.setup.n_player ; i++) {
        var player = {
          id: i,
          pseudo: '',
          role: 'fellow',
          img: '',
          isPwnd: false,
        } 
        this.players.push(player)
      }

      for (i = 0 ; i < this.setup.n_undercover ; i++) {
        var n = Math.floor(Math.random() * this.players.length)
        if (this.players[n].role === 'undercover')
          i-- 
        else
          this.players[n].role = 'undercover'
      }

      for (i = 0 ; i < this.setup.n_mrwhite ; i++) {
        n = Math.floor(Math.random() * this.players.length)
        if (this.players[n].role === 'mrwhite' || this.players[n].role === 'undercover')
          i-- 
        else
          this.players[n].role = 'mrwhite'
      }
      this.gamestep = 1

      this.words = this.getWords()
    }
  },
})
