<template>

   <div class="view" style="overflow:auto;height:100vh;padding-bottom:40px">


        <div style="width: 100%;display:flex;align-items:center;padding:10px;">
            <v-alert v-if="playerStore.gamestep === 1" style="width: 500px">
                1. Tous les joueurs choisissent une carte. 
            </v-alert>
            <v-alert v-if="playerStore.gamestep === 2" style="width: 500px">
                2. Joueur {{playerStore.firstPlayer.pseudo}} joue en premier.  
            </v-alert>
        </div>
        
        <div v-for="player in getPlayers()"  :key="'player-'+player.id">

            <v-dialog persistent v-if="playerStore.gamestep === 1 && (player.pseudo === null || player.pseudo === '')">
                <template v-slot:activator="{ props }">
                    <div v-bind="props" class="button noselect"><img src="/img/fellow.png" alt="fellow"></div>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Nouveau Joueur</span> 
                    </v-card-title>
                    <v-card-text style="display:flex;flex-direction:column;align-items:center;">
                        <div class="word" v-if="player.role === 'mrwhite'" style="font-weight:bold">VOUS ÊTES MR WHITE</div> 
                        <div class="word" v-else-if="player.role === 'undercover'" style="font-weight:bold">{{playerStore.word_undercover}}</div> 
                        <div class="word" v-else style="font-weight:bold">{{playerStore.word_fellow}}</div>   
                            
                        <v-text-field style="width:100%" v-model="tmp_playerName" label="Nom ou pseudo"></v-text-field> 
                        <v-btn block style="margin-bottom:5px" @click="setupPlayer(player.id)" >Valider</v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>



           <div 
                v-if="playerStore.gamestep === 1 && player.pseudo.length > 1"
                class="button noselect"
                style="font-size: 30pt;color:white"
            >
                {{player.pseudo}}
            </div>


            <div 
                v-if="playerStore.gamestep === 2"
                class="button noselect"
                style="font-size: 30pt;color:white"
                @click="player.isPwnd = true"
            >


                <div v-if="!player.isPwnd && (player.role !== 'mrwhite')" src="/img/fellow.png" alt="fellow">{{player.pseudo}}</div>

                <v-dialog v-if="!player.isPwnd && (player.role === 'mrwhite')" v-model="whiteDialog">
                    <template v-slot:activator="{ props }">
                        <div v-bind="props">{{player.pseudo}}</div>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{player.role}}</span> 
                        </v-card-title>
                        <v-card-text style="display:flex;flex-direction:column;align-items:center;">
                            <v-text-field style="width:100%" v-model="whiteTry" label="Une tentative ?"></v-text-field> 
                            <v-btn block style="margin-bottom:5px" @click="checkWhiteTry()" >Valider</v-btn>
                        </v-card-text>
                        <v-alert type='success' v-if="hasWon.length > 0">{{this.hasWon}}</v-alert>
                    </v-card>
                </v-dialog>

                <img v-if="player.isPwnd" :src="'/img/'+player.role+'.png'" :alt="player.role">

            </div>




        </div>

    </div>

</template>

<script>  
import { usePlayerStore } from '@/store/index.js'

export default {
    setup () {
        const playerStore = usePlayerStore()
        if (playerStore.newGame) {
            console.log("Glop")
        }
        return { playerStore }
    },
    data () {
        return {
            tmp_playerName: "", 
            whiteTry: "",
            whiteDialog: false,
            hasWon: "",
        }
    },
    methods: {
        getPlayers () {
            return this.playerStore.players
        },
        setupPlayer (playerId) {
            this.playerStore.setupPlayer(playerId, this.tmp_playerName)
            this.tmp_playerName = ""
        },
        checkWhiteTry () {
            if (this.whiteTry === this.playerStore.word_fellow) {
                this.hasWon = "Bravo ! Le mot était "+this.playerStore.word_fellow
            }
            else {
                this.whiteTry = ""
                this.whiteDialog = false 
            }
        },
    }
}
</script>





<style scoped>
  .view {
      margin: 0px;
      padding: 5px 5pt;
      background-color: var(--generali);
      color: white;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      overflow: auto;
  }
  .word {
    width: 80%;
    display: flex;
    justify-content: center;
    padding: 20px 10px;
    font-size: 24pt;
    border-radius: 10px;
    margin-bottom:20px;
    border: 1px solid black;
  }
  .button {
      cursor: pointer;
      border: 3px solid white;
      background-color: white;
      color:var(--generali);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width:35vw;
      height:35vw;
      font-size: 75pt;
      border-radius: 30px;
      background-color: #2196f3;
  }
  .button img {
    width: 90%;
  }
  .noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
  </style>
  