<template>

<div class="view" style="overflow:auto;height:100vh;padding-bottom:40px">

  <div class="header">
      <h1>Nouveaux mots</h1>
      <v-btn
        icon
        size="large"
        color="white"
        variant="outlined"
        to="/"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
  </div>

  <div class="add-form">
      <ul>
          <li>Un mot par ligne</li>
          <li>Faites des listes d'au-moins 3 mots</li>
      </ul>
      <textarea v-model="words" id="wordList" placeholder="Thé&#10;Café&#10;Chocolat Chaud"></textarea>
      <div class="button noselect" @click="sendWords()">Envoyer</div>
      <v-alert v-if="warn.length > 0" type="warning" style="border-radius:15px;margin-top:20px;">{{warn}}</v-alert>
      <v-alert v-if="success.length > 0"  type="success" style="border-radius:15px;margin-top:20px;">{{success}}</v-alert>
  </div>

</div>

</template>

<script>
  import axios from 'axios';
  
  export default {
    data () {
      return {
        words: "",
        warn: "",
        success: "",
      }
    },
    methods: {
      sendWords () {
          var wordsToSend = this.words.split('\n')
          if (wordsToSend.length < 3) {
              this.success = ""
              this.warn = "Erreur : veuillez envoyer une liste d'au-moins 3 mots !"
              return 
          }
          for (var word of wordsToSend) {
              if (word.length < 3) {
                  this.success = ""
                  this.warn =  "Erreur : l'un de vos mots est vide ou trop court..."
                  return 
              }
          }
          this.warn = ""  
          axios.post("https://api.undercover.nyanpasu.moe/words/", {
              words: wordsToSend,
          })
          .then ( () => {
              this.warn =  ""
              this.success = "Mots ajoutés !"
              this.words = ""
          })
          .catch( () => {
              this.success = ""
              this.warn = "Erreur dans l'envoi de vos mots. Veuillez réessayer plus tard, ou me contacter. "
          })
      },
    },
  }
  
</script>





<style scoped>
  .view {
      position: fixed;
      width: 100%;
      height: 100%;
      margin: 0px;
      padding: 50px 25pt;
      background-color: var(--generali);
      color: white;
      overflow: auto;
  }
  
  #wordList {
      width: 100%;
      height: 300px;
      border: 0px;
      margin-top: 20px;
      padding: 20px;
      font-size: 18pt;
      border-radius: 20px;
      resize: none;
  
  }
  
  .button {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 2px solid white;
      border-radius: 20px;
      background-color: white;
      color: var(--generali);
      padding: 15px;
      font-size: 24pt;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  }
  .button:active {
    color: white;
    background-color: var(--generali);
  }
  
  .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  
  h1 {
      font-size: 24pt;
      font-weight: 500;
  }
  
  .add-form {
      margin-top: 10px;
      font-size: 15pt;
  }
  .add-form ul {
      margin-left: 24px;
  }
  
  .close {
      font-size: 30pt;
  }
  textarea {
    background-color: white;
  }
  
  </style>
  