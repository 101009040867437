<template>

  <div style="display:flex;flex-direction:column;padding: 50px;">

    <img src="/img/home_icon2.png" @click="fullscreen()">

    <v-dialog
      v-model="newGameDialog"
      persistent
    >
      <template v-slot:activator="{ props }">
        <v-btn variant="flat" class="button" size="x-large" v-bind="props">Nouveller Partie</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Nouvelle Partie</span>
        </v-card-title>
          <v-card-text>
            <v-text-field v-model="gameSetup.n_player" label="Nombre de joueurs"></v-text-field>
            <v-text-field v-model="gameSetup.n_undercover" label="Nombre d'Undercovers"></v-text-field>
            <v-text-field v-model="gameSetup.n_mrwhite" label="Nombre de Mr White"></v-text-field>
            <v-btn block style="margin-top:5px;margin-bottom:10px" @click="newGame()">Lancer la partie !</v-btn>
            <v-alert v-if="newGameAlert.length > 0" type="warning">{{newGameAlert}}</v-alert>
          </v-card-text>
        </v-card>
    </v-dialog>




    <v-btn variant="flat" class="button" size="x-large" to="/addWords">Ajouter des mots</v-btn>

  </div>


</template>

<script>
import screenfull from 'screenfull';
import { usePlayerStore } from '@/store';
import router from '@/router';

export default {
  setup () {
    const playerStore = usePlayerStore()
    return { playerStore }
  },
  data () {
    return {
      newGameDialog: false,
      newGameAlert: "",
      gameSetup: {
        n_player: null,
        n_undercover: null,
        n_mrwhite: null,
      }
    }
  },
  methods: {
    fullscreen () {
      if (screenfull.isEnabled) {
        screenfull.toggle();
      }
    },
    newGame () {
      if (this.gameSetup.n_player === null || this.gameSetup.n_undercover === null || this.gameSetup.n_mrwhite === null) {
        this.newGameAlert = "Veuillez remplir tous les champs..."
        return 
      }
      if (this.gameSetup.n_player === "" || this.gameSetup.n_undercover === "" || this.gameSetup.n_mrwhite === "") {
        this.newGameAlert = "Veuillez remplir tous les champs..."
        return 
      }
      if (isNaN(this.gameSetup.n_player) || isNaN(this.gameSetup.n_undercover) || isNaN(this.gameSetup.n_mrwhite)) {
        this.newGameAlert = "Veuillez remplir tous les champs, avec des nombres valides..."
        return 
      }
      if (parseInt(this.gameSetup.n_player) <= parseInt(this.gameSetup.n_mrwhite) + parseInt(this.gameSetup.n_undercover)) {
        this.newGameAlert = "Il doit y avoir plus de joueurs que d'undercovers/mr white..."
        return 
      }
      if (parseInt(this.gameSetup.n_player) <= 2) {
        console.log(this.gameSetup.n_player)
        this.newGameAlert = "Pas jouable à 2 joueurs ou moins..."
        return 
      }
      this.newGameAlert = ""

      this.playerStore.createNewGame(this.gameSetup)
      router.push('/play')
    }
  },
}
</script>


<style>
  img {
    margin: 40px 0px;
  }
  .button {
    font-size: 18pt;
    margin-top:45px;
    border-radius: 10px;
  }
</style>
